<template>
  <div class="bg-white">
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-16">
      <div class="space-y-12">
        <div
          class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none"
        >
          <h2
            class="
              text-3xl
              font-extrabold
              tracking-tight
              sm:text-4xl
              text-red-600
            "
          >
            Náš Team
          </h2>
          <!-- <p class="text-xl text-gray-500">Odio nisi, lectus dis nulla. Ultrices maecenas vitae rutrum dolor ultricies donec risus sodales. Tempus quis et.</p> -->
        </div>
        <ul
          class="grid grid-cols-2 gap-x-6 gap-y-12 space-y-0 sm:grid-cols-3 lg:grid-cols-4 lg:gap-x-8"
        >
          <li v-for="person in people" :key="person.name">
            <div class="space-y-4">
              <div class="aspect-w-2 aspect-h-3">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  :src="person.imageUrl"
                  alt=""
                  loading="lazy"
                />
              </div>

              <div class="space-y-2">
                <div class="text-lg leading-6 font-medium space-y-1">
                  <h3>{{ person.name }}</h3>
                  <p class="text-red-600">{{ person.role }}</p>
                </div>
                <!-- <ul class="flex space-x-5">
                  <li>
                    <a :href="person.twitterUrl" class="text-gray-400 hover:text-gray-500">
                      <span class="sr-only">Twitter</span>
                      <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a :href="person.linkedinUrl" class="text-gray-400 hover:text-gray-500">
                      <span class="sr-only">LinkedIn</span>
                      <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clip-rule="evenodd" />
                      </svg>
                    </a>
                  </li>
                </ul> -->
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <Hiring />
  <GalleryOverview />
</template>

<script>
import Hiring from '@/components/Hiring.vue'
import GalleryOverview from '@/components/GalleryOverview.vue'

const people = [
  {
    name: 'Jitka Maleňáková',
    role: 'Vedoucí laboratoře a provozu, zástupce CEO',
    imageUrl: require('@/assets/img/team/Jitka-Malenakova.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Romana Macková',
    role: 'Zubní technik - keramika, kompozita',
    imageUrl: require('@/assets/img/team/Romana-Mackova.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Andrea Milotová',
    role: 'Zubní technik - keramika, kompozita, implantáty',
    imageUrl: require('@/assets/img/team/Andrea-Milotova.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Lenka Maňasová',
    role: 'Zubní technik - CAD',
    imageUrl: require('@/assets/img/team/Lenka-Manasova.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Adéla Nováková, DiS.',
    role: 'Zubní technik - CAD',
    imageUrl: require('@/assets/img/team/Adela-Novakova.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Anna Vráblová, DiS.',
    role: 'Zubní technik - CAD',
    imageUrl: require('@/assets/img/team/Anna-Vrablova.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Tatiana Petrenco',
    role: 'Zubní technik - modely, 3D tisk, fólie',
    imageUrl: require('@/assets/img/team/Tatiana-Petrenco.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Klára Dlouhá, DiS.',
    role: 'Zubní technik - keramika, 3D tisk',
    imageUrl: require('@/assets/img/team/Klara-Dlouha.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Renata Šenovská, DiS.',
    role: 'CAD technik - snímatelné náhrady',
    imageUrl: require('@/assets/img/team/Renata-Senovska.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Michal Gerych, DiS.',
    role: 'CAD technik',
    imageUrl: require('@/assets/img/team/Michal-Gerych.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Jiří Šudák',
    role: 'Příjem zakázek, CAM výroba',
    imageUrl: require('@/assets/img/team/Jiri-Sudak.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Klára Saidová',
    role: 'CAM - frézovací centrum',
    imageUrl: require('@/assets/img/team/Klara-Saidova.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Nela Hodečková',
    role: 'Skelety',
    imageUrl: require('@/assets/img/team/Nela-Hodeckova.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Dominik Koukal',
    role: 'Skelety',
    imageUrl: require('@/assets/img/team/Dominik-Koukal.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Milan Havíř',
    role: 'Řidič - rozvoz a svoz zakázek v Brně',
    imageUrl: require('@/assets/img/team/Milan-Havir.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Ondřej Kudera, MSc.',
    role: 'CEO',
    imageUrl: require('@/assets/img/team/Ondrej-Kudera.jpeg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
]

export default {
  components: {
    Hiring,
    GalleryOverview,
  },
  setup() {
    return {
      people,
    }
  },
}
</script>
